import { Injectable } from '@angular/core';
import { StripeService } from 'ngx-stripe';

import { ApiHttpService } from 'src/app/_services/api-http.service';

@Injectable({
    providedIn: 'root'
})
export class PaymentService {

    // constructor(private apiHttpService: ApiHttpService, private stripeService: StripeService, private propertyService: PropertyService) { }
    constructor(private apiHttpService: ApiHttpService) { }

    /**
     * redirect to Stripe checkout form
     * @param propertyId property id
     * @param stripeService Stripe service
     */
    payListingFee(propertyId: number, stripeService: StripeService): void {
        // get the Location object's URL's origin.
        const rootUrl = location.origin;
        // get price id from config file.
        const priceId = this.apiHttpService.appConfig.env.priceId;
        // get API key for Stripe
        stripeService.setKey(this.apiHttpService.appConfig.env.stripeAPIKey);

        const successURL = `${rootUrl}/#/dashboard/property/add/success/${propertyId}`;
        const cancelURL = `${rootUrl}/#/dashboard/property/add/cancel/${propertyId}`;

        stripeService.redirectToCheckout({
            mode: 'payment',
            lineItems: [{ price: priceId, quantity: 1 }],
            successUrl: successURL,
            cancelUrl: cancelURL,
        }).subscribe(
            res => {
                console.log('success', res);
            },

            err => {
                console.log('Stripe Error=', err);
            }
        );
    }
}
