import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

import { ApiHttpService } from 'src/app/_services/api-http.service';
import { RequestListModel } from 'src/app/models/request-list.model';
import { RequestType } from 'src/app/constants/request-type.const';

@Injectable({
    providedIn: 'root'
})
export class RequestPropertyService {

    constructor(private http: ApiHttpService) { }

    /**
     * Send requirement of Post that is to send requested message to an agent with message, property id and its name
     */
    postRequireMessage(model: RequestListModel): Observable<any> {
        return this.http.create('requireProperty', model);
    }

    /**
     * Send requirement of Post that is to send instereted message to an agent with property id and its name
     * @param id property id
     */
    postInterestedMessage(id: number): Observable<any> {
        return this.http.create('requireProperty/interested', { propertyId: id, type: RequestType.general });
    }

    /**
     * Requirement of Get to get all requests
     */
    getAllRequestList$(): Observable<RequestListModel[]> {
        const url = 'requireProperty/GetAllRequirements';
        return this.http.get(url);
    }

    getRequestListByAgent$(userId: string): Observable<RequestListModel[]> {
        const url = `requireProperty/getRequestListByAgent/${userId}`;
        return this.http.get(url);
    }

    /**
     * Requirement of Get to get the login user's requests
     */
    getMyRequestList$(): Observable<RequestListModel[]> {
        const url = 'requireProperty';
        return this.http.get(url);
    }
}
